// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Trans } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import subYears from 'date-fns/subYears';
import { ROUTE_ALTINN_SUBSCRIPTION, ROUTE_LOGOUT_DIFI } from 'constant/routes';
import { EMAIL_SUPPORT, HELP_INSTRUCTIONS_URL } from 'constant/externalLinks';
import { AltinnDataT } from 'domain/entity/altinn/AltinnBilag';
import { authSlice } from 'data/store/authReducer';
import { altinnSlice } from 'data/store/altinnReducer';
import { profileSlice } from 'data/store/profileReducer';
import { confirmationSlice } from 'data/store/confirmationReducer';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGlobalTranslation from 'hooks/useGlobalTranlation';
import Icon from 'components/common/Icon';
import Button from 'components/controls/Button';
import SimpleRow from 'components/controls/SimpleRow';
import homeIcon from 'components/svg/home.svg?sprite';
import SimplePage from 'components/common/SimplePage';
import checkIcon from 'components/svg/check-all.svg?sprite';
import documentIcon from 'components/svg/document.svg?sprite';
import profileIcon from 'components/svg/profile_icon.svg?sprite';
import List, { ListTypeT } from './List';
import {
  Header,
  HeaderContainer,
  buttonCss,
  HouseName,
  UserContainer,
  UserName,
  LogoutButton,
  LinkButton,
  LeftBlock,
  HouseData,
  houseIconCss,
  Footer,
  checkIconCss,
  Content,
  ItemTitle,
  ItemSum,
  Buttons,
  HouseSelect,
  SupportLink
} from './styled';
const env = process.env;
//const env = runtimeEnv();

const AltinnPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { g } = useGlobalTranslation();
  const { t: actionsTranslate } = useGlobalTranslation('actions');
  const { t: altinnTranslate } = useGlobalTranslation('altinn');
  const ALTIN_PAGE = env.REACT_APP_ALTIN_URL;

  const { selectedHouse, houseList } = useAppSelector(({ house }) => house);
  const { user } = useAppSelector(({ profile }) => profile);
  const { authProviders, pending: authPending } = useAppSelector(({ auth }) => auth);
  const { altinnData, pending, scattetatenView, taxPendingView, pendingForm, error, successMessage } = useAppSelector(
    ({ altinn }) => altinn
  );
  const { fetchAuthProviders } = authSlice.actions;
  const { setConfirmationData, resetConfirmationData } = confirmationSlice.actions;
  const {
    fetchAltinnData,
    fetchScattetatenView,
    sendNewFormToAltin,
    resetError,
    resetSuccessMessage,
    resetScatteetatenView
  } = altinnSlice.actions;
  const { profileFetching } = profileSlice.actions;

  const [typeList, setTypeList] = useState<ListTypeT>();
  const [isExpired, setIsExpired] = useState(false);
  const [lookAltinData, setLookAltinData] = useState<AltinnDataT>();
  const [selectedHouses, setSelectedHouses] = useState<number[]>([]);
  const DIFI_LINK = authProviders?.find(({ name }) => name === 'Difi').authUrl;

  useEffect(() => {
    if (selectedHouse) dispatch(fetchAltinnData());
  }, [selectedHouse?.id]);

  useEffect(() => {
    setSelectedHouses(houseList.map(({ id }) => id));
  }, [houseList]);

  useEffect(() => {
    if (!user?.subscriptionActive) {
      history.push(ROUTE_ALTINN_SUBSCRIPTION);
    }
    dispatch(fetchAuthProviders());
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (user?.difiData?.expire && !isExpired && user?.difiData?.expire - Date.now() / 1000 > 0) {
      interval = setInterval(() => {
        if (user?.difiData?.expire && !isExpired && user?.difiData?.expire - Date.now() / 1000 < 0) {
          setIsExpired(true);
          dispatch(profileFetching());
          clearInterval(interval);
        }
        if (!user?.difiData) {
          clearInterval(interval);
        }
      }, 1000);
    } else {
      setIsExpired(true);
    }

    return () => clearInterval(interval);
  }, [isExpired]);

  useEffect(() => {
    if (scattetatenView && window) {
      dispatch(
        setConfirmationData({
          title: altinnTranslate('redirectReport.title'),
          onClose: () => {
            dispatch(resetScatteetatenView());
            dispatch(resetConfirmationData());
          },
          cancelButtonProps: {
            children: altinnTranslate('redirectReport.cancel'),
            onClick: () => {
              dispatch(resetScatteetatenView());
              dispatch(resetConfirmationData());
            }
          },
          confirmButtonProps: {
            children: altinnTranslate('redirectReport.ok'),
            href: scattetatenView,
            onClick: () => {
              dispatch(resetScatteetatenView());
              dispatch(resetConfirmationData());
            }
          },
          text: altinnTranslate('redirectReport.text'),
          show: true
        })
      );
      dispatch(resetScatteetatenView());
    }
  }, [scattetatenView]);

  useEffect(() => {
    if (error) {
      dispatch(
        setConfirmationData({
          title: 'Error send to Altinn',
          onClose: () => {
            dispatch(resetConfirmationData());
            dispatch(resetError());
          },
          confirmButtonProps: {
            children: actionsTranslate('cancel'),
            onClick: () => {
              dispatch(resetConfirmationData());
              dispatch(resetError());
            }
          },
          text: (
            <Trans i18nKey={error} t={altinnTranslate}>
              t<SupportLink href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</SupportLink>t
            </Trans>
          ),
          show: true
        })
      );
    }
  }, [error]);

  useEffect(() => {
    if (successMessage && window) {
      dispatch(
        setConfirmationData({
          title: altinnTranslate('redirectAltinn.title'),
          onClose: () => {
            dispatch(resetScatteetatenView());
            dispatch(resetConfirmationData());
          },
          cancelButtonProps: {
            children: altinnTranslate('redirectAltinn.cancel'),
            onClick: () => {
              dispatch(resetScatteetatenView());
              dispatch(resetConfirmationData());
            }
          },
          confirmButtonProps: {
            children: altinnTranslate('redirectAltinn.ok'),
            href: ALTIN_PAGE,
            onClick: () => {
              dispatch(resetScatteetatenView());
              dispatch(resetConfirmationData());
            }
          },
          text: altinnTranslate('redirectAltinn.text'),
          show: true
        })
      );
      dispatch(resetSuccessMessage());
    }
  }, [successMessage]);

  if (!selectedHouse || pending || authPending) {
    return null;
  }

  const getScattetatenView = () => {
    dispatch(fetchScattetatenView({ houseIds: selectedHouses }));
  };

  const handleSendForm = () => {
    dispatch(sendNewFormToAltin({ houseIds: selectedHouses }));
  };

  const handleSelectHouseClick = (id: number) => {
    setSelectedHouses((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((houseId) => id !== houseId);
      }

      return [...prevState, id];
    });
  };

  return (
    <SimplePage
      header={
        <HeaderContainer>
          <UserContainer>
            <UserName>{user?.difiData && `${user.difiData.party.name} (${user?.difiData?.ssn})`}</UserName>
            {user?.difiData && <LogoutButton to={ROUTE_LOGOUT_DIFI}>{g('logout')}</LogoutButton>}
          </UserContainer>
          <Header>
            <LeftBlock>
              <span>{altinnTranslate('selectHouse')}</span>
              {houseList.map((house) => (
                <HouseSelect
                  key={house.id}
                  isActive={selectedHouses.includes(house.id)}
                  onClick={() => handleSelectHouseClick(house.id)}
                >
                  {house.name}
                </HouseSelect>
              ))}
              <HouseSelect
                isActive={selectedHouses.length === houseList.length}
                onClick={() => {
                  if (selectedHouses.length !== houseList.length) {
                    setSelectedHouses(houseList.map(({ id }) => id));
                    return;
                  }
                  setSelectedHouses([]);
                }}
              >
                {selectedHouses.length !== houseList.length && altinnTranslate('selectAllHouses')}
                {selectedHouses.length === houseList.length && altinnTranslate('unselectAllHouses')}
              </HouseSelect>
            </LeftBlock>
          </Header>
        </HeaderContainer>
      }
    >
      {!typeList && selectedHouses.length !== 0 && (
        <>
          {selectedHouses.map((currentId) => {
            const currentHouse = houseList.find(({ id }) => currentId === id);
            const currentAltinnData = altinnData?.find(({ houseId }) => currentId === houseId);

            return (
              <React.Fragment key={currentId}>
                {currentHouse && (
                  <HouseData>
                    <HouseName>
                      <Icon icon={homeIcon} css={houseIconCss} />
                      {currentHouse.name}
                    </HouseName>
                    {g('Reporting year')} {format(subYears(new Date(), 1), 'yyyy')}
                  </HouseData>
                )}
                {altinnData && (
                  <Content>
                    <SimpleRow
                      onClick={() => {
                        setTypeList('bilags');
                        setLookAltinData(currentAltinnData);
                      }}
                      name={<ItemTitle>{g('expensesBilag')}</ItemTitle>}
                      info={<ItemSum color={'red01'}>{currentAltinnData?.bilagsSum} NOK</ItemSum>}
                    />
                    <SimpleRow
                      onClick={() => {
                        setTypeList('depreciationBilag');
                        setLookAltinData(currentAltinnData);
                      }}
                      name={<ItemTitle>{g('depreciationBilag')}</ItemTitle>}
                      info={<ItemSum color={'orange01'}>{currentAltinnData?.depreciationSum} NOK</ItemSum>}
                    />
                    <SimpleRow
                      onClick={() => {
                        setTypeList('incomeBilags');
                        setLookAltinData(currentAltinnData);
                      }}
                      name={<ItemTitle>{g('incomeBilag')}</ItemTitle>}
                      info={<ItemSum color={'green01'}>{currentAltinnData?.incomebilagsSum} NOK</ItemSum>}
                    />
                  </Content>
                )}
              </React.Fragment>
            );
          })}
          <Footer>
            {user?.difiData && !isExpired && (
              <Buttons>
                <Button isLoading={taxPendingView} disabled={pendingForm} onClick={getScattetatenView}>
                  <Icon icon={documentIcon} css={checkIconCss} />
                  {actionsTranslate(actionsTranslate('openTaxView'))}
                </Button>
                <Button isLoading={pendingForm} disabled={taxPendingView} onClick={handleSendForm}>
                  <Icon icon={checkIcon} css={checkIconCss} />
                  {actionsTranslate('sendToAltin')}
                </Button>
              </Buttons>
            )}
            {(!user?.difiData || isExpired) && (
              <LinkButton href={DIFI_LINK}>
                <Button as={'span'} css={buttonCss}>
                  <Icon icon={profileIcon} css={checkIconCss} />
                  {altinnTranslate('login')}
                </Button>
              </LinkButton>
            )}
            {/*<Link href={HELP_INSTRUCTIONS_URL} target={'_blank'}>*/}
            {/*  {altinnTranslate('links.instructionsForSending')}*/}
            {/*</Link>*/}
          </Footer>
        </>
      )}
      {lookAltinData && typeList && <List type={typeList} setType={setTypeList} altinnData={lookAltinData} />}
    </SimplePage>
  );
};

export default AltinnPage;
